import React from "react";
import { Modal } from "react-bootstrap";

function PaymetFailModel({ show, handleClose, reason1,reason2,reason3 }) {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ border: "none" }}>
          <Modal.Title>
            <h5
              className="modal-title font-weight-bold ps-3"
              id="exampleModalLabel"
              style={{ color: "#CF4646", fontSize: "35px", fontWeight: "600" }}
            >
              Payment Failed
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            class="modal-body"
            style={{ backgroundColor: "#ECECEC", borderRadius: "10px" }}
          >
            <div
              class="PaymentFailsDetails"
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "15px",
                letterSpacing: " 1.5px",
                fontWeight: "500",
                lineHeight: "10px",
              }}
            >
              <p>Authorize.Net failure reason:{reason1}</p>
              <p>AVS Response: {reason2}</p>
              <p>CVV2 Response: {reason3} </p>
            </div>
          </div>
        </Modal.Body>

        <button
          type="button"
          style={{
            background: "none",
            border: "none",
            position: "absolute",
            alignItems: "end",
            textAlign: "end",
            width: "100%",
            fontSize: "30px",
            top: "-20px",
            left: "13px",
          }}
          onClick={handleClose}
        >
          <i
            class="fa-solid fa-circle-xmark"
            style={{
              backgroundColor: "#fff",
              borderRadius: "20px",
              color: "#CF4646",
            }}
          ></i>
        </button>
      </Modal>
    </div>
  );
}

export default PaymetFailModel;
