import React, { useState, useEffect } from "react";
import "./LockAndUnlockModule.css";
import { useDispatch } from "react-redux";
import { ActionLockUnlockUser } from "../../store/actions/actions-server-data";
import { Modal, Button } from "react-bootstrap";

export default function LockAndUnlockModule({ userIds, isDisabled: initialIsDisabled, userName }) {
  const dispatch = useDispatch();
  const [isLocked, setIsLocked] = useState(false);
  const [lockOpen, setLockOpen] = useState("faLockOpen");
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(initialIsDisabled);

  useEffect(() => {
    setIsDisabled(initialIsDisabled);
  }, [initialIsDisabled]);

  const handleToggle = () => {
    if (!isLocked) {
      setShowModal(true);
    } else {
      setIsLocked(false);
      setLockOpen("faLockOpen");
      setIsDisabled(true);
    }
  };

  const handleClose = () => setShowModal(false);

  const handleConfirm = () => {
    setIsLocked(true);
    setLockOpen("faLock");
    console.log("unlocked", userIds);
    dispatch(ActionLockUnlockUser({ userId: userIds }));
    setShowModal(false);
    setIsDisabled(false);
  };

  return (
    <div>
      <div className="LockAndUnlockModule" key={userIds}>
        <input
          type="checkbox"
          id="darkmode-toggle"
          className="openBtn"
          disabled={isDisabled}
        />
        <label htmlFor="darkmode-toggle" id="LockAndUnlock">
          <div
            className={`toggle-button ${
              isDisabled ? "DisableLocked" : isLocked ? "locked" : "unlocked"
            }`}
            onClick={isDisabled ? undefined : handleToggle}
          >
            <div className="icon" id={lockOpen}>
              {isDisabled || isLocked ? (
                <i className="fa-solid fa-lock-open" id="faLockOpenIcons"></i>
              ) : (
                <i className="fa-solid fa-lock" id="faLockIcons"></i>
              )}
            </div>
            <div
              className="Lockbtn"
              id={isDisabled || isLocked ? "Lockbtn2" : "Lockbtn"}
            ></div>
          </div>
        </label>
        <span className={isDisabled ? "" : "toggle"}></span>
        <input
          type="checkbox"
          id="darkmode-toggle"
          className="closeBtn"
          disabled={isDisabled}
        />
        <label htmlFor="darkmode-toggle" id="lockIcons"></label>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center fs-5">
          Are you sure you want to unlock  all Modules for {userName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}