import React, { useEffect, useState } from "react";
import "./RegistrationDetails.css";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ExportBtnIcons from "../../Assets/Admin LMS Image/Icons/page-csv.svg";
import { ActionRegisterationDetails } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import SiderIcons from "../../components/SVG Icons/Svg";

export default function RegistrationDetails() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const ServerData = mystate?.rServerData;
  const isLoading = mystate?.rLoading;
  const callAPILoad = isLoading?.RegisterationLoading;
  const userAccessToken = ServerData?.userAccessToken || null;
  const RegistrationDetails = ServerData?.RegisterationDetails?.data || [];
  const itemsPerPage = 9;

  const [currentPage, setCurrentPage] = useState(1);
  const [CustomerRegData, setCustomerRegData] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({
    995: false,
    500: false,
    250: false,
  });
  const [registrationfilteredData, setRegistrationfilteredData] = useState([]);
  const [fromDatePicker, setFromDatePicker] = useState(null);
  const [toDatePicker, setToDatePicker] = useState(null);
  const [isDropdownToggle, setIsDropdownToggle] = useState(true);

  useEffect(() => {
    dispatch(ActionRegisterationDetails({ token: userAccessToken }));
  }, [dispatch]);

  useEffect(() => {
    const reversedData = [...RegistrationDetails].reverse();
    setInitialData(reversedData);
    setRegistrationfilteredData(reversedData);
  }, [RegistrationDetails]);

  useEffect(() => {
    if (fromDatePicker && toDatePicker && fromDate) {
      toDatePicker.set("minDate", fromDate);
    }
  }, [fromDate, fromDatePicker, toDatePicker]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsDropdownToggle(false);
      } else {
        setIsDropdownToggle(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const timeDateFormate = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredResult = initialData;
    if (CustomerRegData) {
      filteredResult = filteredResult.filter(
        (item) =>
          item.CustomerEmail.toLowerCase().includes(
            CustomerRegData.trim().toLowerCase()
          ) ||
          item.UserId == CustomerRegData.trim() ||
          item.Firstname.toLowerCase().includes(
            CustomerRegData.trim().toLowerCase()
          ) ||
          item.Lastname.toLowerCase().includes(
            CustomerRegData.trim().toLowerCase()
          ) ||
          `${item.Firstname.toLowerCase()} ${item.Lastname.toLowerCase()}`.includes(
            CustomerRegData.trim().toLowerCase()
          )
      );
    }
    
    if (fromDate && toDate) {
      const fromdate = new Date(fromDate);
      const todate = new Date(toDate);
      todate.setHours(23, 59, 59, 999);
      filteredResult = filteredResult.filter((item) => {
        let itemDate;
        if (item.RegisteredAt == null) {
          itemDate = 0;
        } else {
          itemDate = new Date(item.RegisteredAt.split("T")[0]);
        }
        return itemDate >= fromdate && itemDate <= todate;
      });
    }
    setRegistrationfilteredData(filteredResult);
    setCurrentPage(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fromDate") {
      setFromDate(value);
    } else if (name === "toDate") {
      setToDate(value);
    } else if (name === "CustomerRegData") {
      setCustomerRegData(value);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(registrationfilteredData.length / itemsPerPage)
      )
    );
  };

  const handleAmountClick = (status) => {
    if (!initialData) return;

    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [status]: !prevStates[status],
    }));

    setInitialData((prevSelectedStatuses) => {
      const isChecked = !checkboxStates[status];
      let newSelectedStatuses;

      if (isChecked) {
        newSelectedStatuses = [...prevSelectedStatuses, status];
      } else {
        newSelectedStatuses = prevSelectedStatuses.filter((s) => s !== status);
      }

      const filteredAmountData = initialData.filter(
        (item) =>
          newSelectedStatuses.includes(item.OrderAmount) ||
          newSelectedStatuses.includes(item.SubscriptionAmount)
      );
      setCustomerRegData("");
      setFromDate("");
      setToDate("");
      if (filteredAmountData.length) {
        setRegistrationfilteredData(filteredAmountData);
      } else if (!isChecked) {
        setRegistrationfilteredData(RegistrationDetails);
      } else {
        setRegistrationfilteredData(0);
      }
      return newSelectedStatuses;
    });

    setCurrentPage(1);
  };
  const handleExportBtnClick = () => {
    window.open(
      // "https://lmsreactbackend.helenzysinc.com/getRegisteredDetails_csv",
      "https://onlinetraining.drgarciabiomagnetism.com/getRegisteredDetails_csv",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handelRegResetBtn = () => {
    setCustomerRegData("");
    setFromDate("");
    setToDate("");
    setRegistrationfilteredData(RegistrationDetails);
    setCurrentPage(1);
  };

  const paginatedData = registrationfilteredData
    ? registrationfilteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    : null;


    const totalPages = Math.ceil(registrationfilteredData.length / itemsPerPage);

    const handlePageChange = (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };
  
    const getPageRange = (currentPage) => {
      const start = Math.floor((currentPage - 1) / 10) * 10 + 1;
      const end = Math.min(start + 9, totalPages);
      return { start, end };
    };

  return (
    <div>
      {callAPILoad && RegistrationDetails.length == 0 ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="filterOption d-flex ">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={3} lg={4} className="p-0">
                    <div className="">
                      <div className="">
                        <div class="form-group">
                          <input
                            type="text"
                            name="CustomerRegData"
                            class="form-control idInputValue"
                            id="RegInputValue"
                            value={CustomerRegData}
                            onChange={handleChange}
                            placeholder="Search by email ID, name or customer ID"
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={9} lg={8}>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="me-3 position-relative">
                              <Flatpickr
                                className="form-control"
                                placeholder="Registered From"
                                options={{
                                  dateFormat: "Y-m-d",
                                  altFormat: "d M Y",
                                  altInput: true,
                                  allowInput: true,
                                  onReady: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    setFromDatePicker(instance);
                                  },
                                }}
                                value={fromDate}
                                onChange={([date]) => setFromDate(date)}
                              />
                            </div>

                            <div className=" position-relative">
                              <Flatpickr
                                className="form-control"
                                placeholder="Registered To"
                                options={{
                                  dateFormat: "Y-m-d",
                                  altFormat: "d M Y",
                                  altInput: true,
                                  allowInput: true,
                                  onReady: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    setToDatePicker(instance);
                                  },
                                }}
                                value={toDate}
                                onChange={([date]) => setToDate(date)}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              id="filterSubmitBtn"
                              class="btn btn-primary ms-3"
                            >
                              search
                            </button>
                          </div>
                          <div className="ResetBtn">
                            <button
                              className="btn "
                              id="ResetBtn"
                              onClick={handelRegResetBtn}
                            >
                              <span id="reset">Reset</span>
                              <span id="reseticon">
                                <i class="fa-solid fa-rotate-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between ms-3 ">
                        <div className="exportPaymentStatusBtn me-2">
                          <button
                            className="exportPaymentStatusBtn px-3"
                            onClick={handleExportBtnClick}
                          >
                            <span className="largescreen">
                              <img
                                src={ExportBtnIcons}
                                alt="Export Icon"
                                className="pe-2"
                                width="18"
                              />
                              Export
                            </span>
                            <span className="smallScreen">
                              <SiderIcons icons={9} />
                            </span>
                          </button>
                        </div>
                        <div className="ms-3">
                          <div class="dropdown">
                            <button
                              className={`btn ${
                                isDropdownToggle ? "dropdown-toggle" : ""
                              }`}
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="LargeScreenFilter">Filter</span>
                              <span className="smallScreen">
                                <SiderIcons icons={10} />
                              </span>
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a class="dropdown-item">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheck995"
                                      checked={checkboxStates["995"]}
                                      onClick={() => handleAmountClick("995")}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexCheck995"
                                    >
                                      &#36; 995
                                    </label>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheck500"
                                      checked={checkboxStates["500"]}
                                      onClick={() => handleAmountClick("500")}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexCheck500"
                                    >
                                      &#36; 500
                                    </label>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheck250"
                                      checked={checkboxStates["250"]}
                                      onClick={() => handleAmountClick("250")}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexCheck250"
                                    >
                                      &#36; 250
                                    </label>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          {registrationfilteredData == 0 ? (
            <div
              className="text-center mt-5"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              <p> No Data Found</p>
            </div>
          ) : (
            <div className="">
              <div
                className="outputTables table mt-4"
                style={{ overflowX: "auto" }}
              >
                <table className="col-12">
                  <thead style={{ backgroundColor: "#402F7B", color: "white" }}>
                    <tr id="RegTableHead">
                      <th style={{ whiteSpace: "nowrap" }}>Registered On</th>
                      <th style={{ whiteSpace: "nowrap" }}>Customer ID</th>
                      <th style={{ whiteSpace: "nowrap" }}>Name</th>
                      <th style={{ whiteSpace: "nowrap" }}>Customer Email</th>
                      <th style={{ whiteSpace: "nowrap" }}>Contact Number</th>
                      <th style={{ whiteSpace: "nowrap" }}>Purchase Date</th>
                      <th style={{ whiteSpace: "nowrap" }}>Date Of Birth</th>
                      <th style={{ whiteSpace: "nowrap" }}>Age</th>
                      <th style={{ whiteSpace: "nowrap" }}>Address</th>
                      <th style={{ whiteSpace: "nowrap" }}>City</th>
                      <th style={{ whiteSpace: "nowrap" }}>Zipcode</th>
                      <th style={{ whiteSpace: "nowrap" }}>Country</th>
                      <th style={{ whiteSpace: "nowrap" }}>State</th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        How Did You Hear AboutUs
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>Course type</th>
                      <th style={{ whiteSpace: "nowrap" }}>Order Id</th>
                      <th style={{ whiteSpace: "nowrap" }}>Course Id</th>
                      <th style={{ whiteSpace: "nowrap" }}>Order Amount</th>
                      <th style={{ whiteSpace: "nowrap" }}>Order Currency</th>
                      <th style={{ whiteSpace: "nowrap" }}>Order Status</th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Order Session Progress
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Order Lecture Progress
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Order Sublecture Progress
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Order Assessment Session Stage
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Order Assessment Lecture Stage
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Order Assessment Session Status
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>Expiration On</th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Course type
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Order Id
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Course Id
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Amount
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Tenure
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Currency
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Status
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>Subscription On</th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Subscription Updated On
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift Order Id</th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift GiftingName</th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift GiftingDate</th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift Email</th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift By User</th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift OrderGifted</th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift Created On</th>
                      <th style={{ whiteSpace: "nowrap" }}>Gift Updated On</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {paginatedData.map((item, index) => (
                      <tr key={index} id={"RegTableData"}>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {timeDateFormate(item.RegisteredAt) || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.UserId || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Firstname + " " + item.Lastname || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.CustomerEmail || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.ContactNumber || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.PurchasedOn == null
                            ? "NA"
                            : timeDateFormate(item.PurchasedOn)}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.DateOfBirth == null
                            ? "NA"
                            : timeDateFormate(item.DateOfBirth) || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Age || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Address || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.City || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Zipcode || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Country || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.State || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.HowDidYouHearAboutUs || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Coursetype || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderId || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.CourseId || "10"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Type == "Order"
                            ? item.OrderAmount || "NA"
                            : item.SubscriptionAmount || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderCurrency || "USD"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderStatus || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderSessionProgress || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderLectureProgress || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderSublectureProgress || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderAssessmentSessionStage || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderAssessmentLectureStage || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderAssessmentSessionStatus || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.ExpirationOn == "NA"
                            ? "NA"
                            : (item.ExpirationOn &&
                                timeDateFormate(item.ExpirationOn)) ||
                              "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionCoursetype || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionOrderId || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionCourseId || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionAmount || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionTenure || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionCurrency || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionStatus || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionOn == null
                            ? "NA"
                            : timeDateFormate(item.SubscriptionOn) || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SubscriptionUpdatedOn == null
                            ? "NA"
                            : timeDateFormate(item.SubscriptionUpdatedOn) ||
                              "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftOrderId || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftGiftingName || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftGiftingDate || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftEmail || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftByUser || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftOrderGifted || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftCreatedOn || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.GiftUpdatedOn || "NA"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
             <div className="d-flex justify-content-center">
             {RegistrationDetails.length > 9 && (
               <div className="d-flex align-items-center">
               {/* Previous Button */}
               {totalPages > 1 && (
                 <Button
                   variant="link"
                   onClick={() => handlePageChange(currentPage - 1)}
                   disabled={currentPage === 1}
                   className="pagination-button"
                   style={{
                     color: "#000",
                     border: "none",
                     textDecoration: "none",
                   }}
                 >
                   <i className="fa-solid fa-backward-step"></i> Prev
                 </Button>
               )}

               {/* Page Numbers */}
               <div className="d-flex text-center align-items-center">
                 {Array.from(
                   {
                     length:
                       getPageRange(currentPage).end -
                       getPageRange(currentPage).start +
                       1,
                   },
                   (_, i) => {
                     const page = getPageRange(currentPage).start + i;

                     return (
                       <Button
                         key={page}
                         variant={
                           page === currentPage ? "primary" : "link"
                         }
                         onClick={() => handlePageChange(page)}
                         disabled={page === currentPage}
                         className={`mx-1 pagination-button ${
                           page === currentPage ? "active" : ""
                         }`}
                         style={{
                           backgroundColor:
                             totalPages > 1 && page === currentPage
                               ? "#402F7B"
                               : "transparent",
                           color: page === currentPage ? "#fff" : "#000",
                           border: "none",
                           textDecoration: "none",
                         }}
                       >
                         {totalPages > 1 && page}
                       </Button>
                     );
                   }
                 )}

                 {/* Ellipsis */}
                 {totalPages >= 10 && currentPage <= Math.floor(totalPages / 10) * 10 && (
                   <span>...</span>
                 )}

                 {/* Last Page Button */}
                 {totalPages > 10 && (
                   <Button
                     variant="link"
                     onClick={() => handlePageChange(totalPages)}
                     disabled={currentPage === totalPages}
                     className="pagination-button"
                     style={{
                       color: "#000",
                       border: "none",
                       textDecoration: "none",
                     }}
                   >
                     {currentPage <= Math.floor(totalPages / 10) * 10 ? totalPages : ""}
                   </Button>
                 )}
               </div>
               {/* Next Button */}
               {totalPages > 1 && (
                 <Button
                   variant="link"
                   onClick={() => handlePageChange(currentPage + 1)}
                   disabled={currentPage === totalPages}
                   className="pagination-button"
                   style={{
                     color: "#000",
                     border: "none",
                     textDecoration: "none",
                   }}
                 >
                   Next <i className="fa-solid fa-forward-step"></i>
                 </Button>
               )}
             </div>
              )}
             </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
