import React, { useEffect, useState } from "react";
import "./AdminLogin.css";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Blowfish from "blowfish";
import { Buffer } from "buffer";
import { ActionAdminLogin } from "../../store/actions/actions-user";
import { NotificationContainer } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function AdminLogin() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const userAccessToken = mystate?.rServerData.userAccessToken;
  const user = mystate.rLoading;

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [remember, setRemember] = useState(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    return rememberedEmail && rememberedPassword ? true : false;
  });
  const [isloading, setLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [updateType, setUpdatedType] = useState("password");
  const [eyeColor, setEyeColor] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const passwordViewHandler = () => {
    if (updateType === "text") {
      setUpdatedType("Password");
      setEyeColor(false);
    } else {
      setUpdatedType("text");
      setEyeColor(true);
    }
  };

  useEffect(() => {
    setLoading(user.AdminLoginLoading ? false : true);
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    if (rememberedEmail && rememberedPassword) {
      try {
        const base64Decode = (str) => {
          const decoded = Buffer.from(str, "base64").toString("utf-8");
          return decoded.replace(/\0/g, "");
        };
        const bf = new Blowfish("R1iN1EuGA");
        const decryptedPassword = bf.decrypt(base64Decode(rememberedPassword));
        var Password = decryptedPassword.replace(
          /[^a-zA-Z0-9@~!`#$%^&*()]/g,
          ""
        );
        setCredentials({
          email: rememberedEmail,
          password: Password,
        });
      } catch (error) {
        console.error("Error decrypting password:", error);
      }
    }
  }, [user, remember]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handelRememberClick = () => {
    setRemember((prevRemember) => !prevRemember);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!credentials.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      newErrors.email = "Please enter a valid email address";
    }

   if (!credentials.password) {
      newErrors.password = "Password is required";
    } else if (credentials.password.length < 8) {
      newErrors.password = "Password must have at least 8 characters";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = {
        email: credentials.email,
        password: credentials.password,
        role_id: 1,
        remember: remember,
      };
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 2500);
      dispatch(ActionAdminLogin(response));

      if (remember) {
        var bf = new Blowfish("R1iN1EuGA");
        var encryptedPassword = bf.encrypt(credentials.password);
        var bufferedPassword =
          Buffer.from(encryptedPassword).toString("base64");
        localStorage.setItem("rememberedEmail", credentials.email);
        localStorage.setItem("rememberedPassword", bufferedPassword);
        localStorage.setItem("userAccessToken", userAccessToken);
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response?.data?.message || error.message
      );
      setErrors({ form: "An error occurred while logging in" });
    }
  };

  return (
    <div id="LoginPage">
      <div className="px-md-5 mx-md-5 py-5">
        <div className="px-md-5 mx-5 ">
          <div className="Loginpage text-center">
            <div className="mx-lg-5 px-lg-5 mx-md-0 px-md-0">
              <div className="loginTitle mt-4 text-uppercase">login</div>
              <div className="mx-lg-5 px-lg-5 mx-md-0 px-md-0">
                <div className="LoginForms">
                  <div className="formInput">
                    <Form onSubmit={(values) => handleSubmit(values)}>
                      <Form.Group
                        className="text-start"
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label id="LoginFromLabel">Login ID</Form.Label>
                        <Form.Control
                          type="email"
                          className="loginInput"
                          placeholder="Enter your login ID"
                          name="email"
                          id="loginInputs"
                          value={credentials.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <p className="text-danger text-start mt-1 position-absolute">
                            {errors.email}
                          </p>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="text-start mt-4"
                        md="4"
                        controlId="validationCustom02"
                      >
                        <Form.Label id="LoginFromLabel">Password</Form.Label>
                        <InputGroup className="">
                          <Form.Control
                            type={updateType}
                            className="loginInput"
                            placeholder="Enter your Password"
                            name="password"
                            id="loginInputs"
                            value={credentials.password}
                            onChange={handleInputChange}
                          />
                          <InputGroup.Text
                            onClick={passwordViewHandler}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#ffffff",
                              border: "none",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={eyeColor ? faEyeSlash : faEye}
                              id={eyeColor ? "eye-color" : ""}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                        {errors.password && (
                          <p className="text-danger text-start position-absolute">
                            {errors.password}
                          </p>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-between mt-4">
                        <div className="position-relative login-input">
                          <input
                            type="checkbox"
                            name="rememberMe"
                            id="rememberMe"
                            checked={remember}
                            onChange={handelRememberClick}
                          />
                          <label
                            htmlFor="rememberMe"
                            className="form-check-label px-2 "
                            id="remember"
                          >
                            Remember Me
                          </label>
                        </div>
                        <div className="text-end ForgotPassword">
                          <a href="AdminForgotPassword">Forgot Password?</a>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <button
                          className="LoginBtn mt-4"
                          type="submit"
                          disabled={isButtonDisabled}
                        >
                          {isloading ? "LOGIN" : "Logging in..."}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}
export default AdminLogin;
