import UserAPI from "../../api/user";
import { ActionClearUtils, ActionOpenNotification } from "./actions-utils-data";
import { USER_ACTIONS } from "../actions-names/users";
import { ActionLoadingUpdate } from "./actions-loading";
import { ActionSessionClear } from "./actions-session";
import { ActionRouteNavigate } from "./actions-route";
import {  ActionClearServer,  ActionServerData  } from "./actions-server-data";
import { localStorageApi } from "../../api/storage";
import ROUTES from "../../configs/routes";

export function ActionUserUpdate(key, data) {
  return {
    type: USER_ACTIONS.UPDATE,
    key,
    data,
  };
}

export function ActionClearUsersWithKey(key) {
  return (dispatch) => {
    dispatch(ActionUserUpdate(key, {}));
  };
}

export function ActionClearUser() {
  return {
    type: USER_ACTIONS.CLEAR,
  };
}


export function ActionAdminLogin(param) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("AdminLoginLoading", true));
    UserAPI.AdminLogin(param)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("AdminLogin", res?.data))
          dispatch(ActionServerData("userAccessToken", res?.data?.token))
          dispatch(ActionRouteNavigate(ROUTES.AdminDashboard));
        } else {
          dispatch(ActionOpenNotification("info", "failed", res.message));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("AdminLoginLoading", false)));
  };
}


export function ActionForgotPassword(params) {
  return (dispatch) => {
    UserAPI.ForgotPassword(params)
    .then((res) => {
      dispatch(ActionLoadingUpdate("ForgotPasswordLoading", true));
      if (res.success === true) {
        dispatch(ActionServerData("ForgotPassword",res));
        dispatch(ActionRouteNavigate(ROUTES.AdminUpdatepassword, res));
        dispatch(ActionOpenNotification("success", "success", res.message));
      } else {
        dispatch(ActionOpenNotification("info", "failed", res.message));
      }
    })
    .catch ((err) => console.error(err))
    .finally(()=>dispatch(ActionLoadingUpdate("ForgotPassword", false)))
  };
}


export function ActionUpdatePassword(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("updatePasswordLoading", true));
    UserAPI.RestPassword(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("updatePassword", res));
          dispatch(ActionOpenNotification("success", "success", res.message));
          dispatch(ActionRouteNavigate("/", res));
        } else {
          dispatch(ActionOpenNotification("info", "failed", res.message));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("updatePasswordLoading", false)));
  };
}


export function ActionLogout() {
  return (dispatch) => {
      dispatch(ActionSessionClear());
      dispatch(ActionClearServer());
      dispatch(ActionClearUser());
      dispatch(ActionClearUtils());
      localStorageApi.clear();
    dispatch(ActionRouteNavigate("/"));
  };
}
