/* eslint-disable prefer-destructuring */
import ROUTES from "./routes";
import API_MAP from "./url-api";


// Reacr URL
// export const API_URL = "https://lmsreactbackend.helenzysinc.com";

//production URL 
// export const API_URL = "https://onlinetraining.drgarciabiomagnetism.com";

// dev URL
export const API_URL = "https://dev.onlinetraining.drgarciabiomagnetism.com";

export const API_SETTINGS = {
  API_URL,
};

export const config = {
  ROUTES,
  API_SETTINGS,
  API_MAP,
  API_URL,
};

export const ROLES = {
  MANAGER: 1,
  DM: 2,
  LEAD: 3,
  MEMBER: 4,
};

export default function ConfigStorage() {
  return config;
}
