export default {
  ADMINLOGIN: "/adminLogin",
  ADMINFORGOTPASSWORD: "/adminForgotpassword",
  ADMINRESETPASSWORD: "/adminUpdatepassword",
  ADMINDASHBOARD:"/getdashboard",
  ADMINUNLOCKMODULES:"/getOrdersList",
  ADMINPAYMENTSTATUS:"/getAllOrderEntries",
  ADMINREGISTRATIONDETAILS:"/getAllUserList",
  ADMINCOURSEDETAILS:"/trackProgress",
  ADMINCUSTOMERDETAILS:"/getCustomerDetails",
  ADMINSTUDENTPROGRESS:"/getProgressofStudent",
  ADMINLOCKUNLOCKUSER:"/lockUnlockUser",
  ADMINPRODUCTDETAILS:"/getproductdetails",
};
