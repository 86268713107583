import React, { useEffect, useRef, useState } from "react";
import "./StaticLayout.css";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";

export default function StaticLayout({children}) {

  const [mainContentHeight, setMainContentHeight] = useState(0);
  const rightSideMainPageRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (rightSideMainPageRef.current) {
        const height = rightSideMainPageRef.current.offsetHeight;
        setMainContentHeight(height);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);
    if (rightSideMainPageRef.current) {
      resizeObserver.observe(rightSideMainPageRef.current);
    }

    return () => {
      if (rightSideMainPageRef.current) {
        resizeObserver.unobserve(rightSideMainPageRef.current);
      }
    };
  }, []);

  const windowHeight = window.innerHeight;
  const minHeight = 630;
  const sideBarHeight = Math.max(mainContentHeight, windowHeight, minHeight);

  return (
    <div>
      <div className="HeaderPage">
        <Header />
      </div>
      <div className="w-100 d-flex mt-2">
        <div className="leftSideNavbar d-none d-lg-block" style={{height:`${mainContentHeight}px`}}>
          <SideBar bodyLength={sideBarHeight} />
        </div>
        <div className="RightSideMainpage px-4 py-3" ref={rightSideMainPageRef}>

            {children}
            
        </div>
      </div>
    </div>
  );
}
