import React, { useEffect, useState } from "react";
import "./StudentProgress.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaymetFailModel from "../../components/Model/PaymetFailModel";
import { useDispatch, useSelector } from "react-redux";
import ExportBtnIcons from "../../Assets/Admin LMS Image/Icons/page-csv.svg";
import { ActionStudentProgress } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "flatpickr/dist/themes/light.css";
import SiderIcons from "../../components/SVG Icons/Svg";

export default function StudentProgress() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const ServerData = mystate?.rServerData;
  const isLoading = mystate?.rLoading;
  const callAPILoad = isLoading?.PaymentStatusLoading;
  const userAccessToken = ServerData?.userAccessToken || null;
  const studentProgress = ServerData?.studentprogress?.data || [];

  const itemsPerPage = 9;

  const [studentfilteredData, setStudentFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [CustomerStudentData, setCustomerStudentData] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [fromDatePicker, setFromDatePicker] = useState(null);
  const [toDatePicker, setToDatePicker] = useState(null);

  useEffect(() => {
    dispatch(ActionStudentProgress({ token: userAccessToken }));
  }, [dispatch, userAccessToken]);

  useEffect(() => {
    const reversedData = [...studentProgress].reverse();
    setInitialData(reversedData);
    setStudentFilteredData(reversedData);
  }, []);

  useEffect(() => {
    if (fromDatePicker && toDatePicker && fromDate) {
      toDatePicker.set("minDate", fromDate);
    }
  }, [fromDate, fromDatePicker, toDatePicker]);


  const timeDateFormate = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(studentfilteredData.length / itemsPerPage)
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredResult = initialData;

    if (CustomerStudentData) {
      filteredResult = filteredResult.filter((item) => {
        const customerEmail = item.student_email?.toLowerCase() || "";
        const firstName = item.firstname?.toLowerCase() || "";
        const lastName = item.lastname?.toLowerCase() || "";
        const userId = item.userid;
        const OrderId = item.orderid;

        return (
          customerEmail.includes(CustomerStudentData.trim().toLowerCase()) ||
          userId == CustomerStudentData.trim() ||
          OrderId == CustomerStudentData.trim() ||
          firstName.includes(CustomerStudentData.trim().toLowerCase()) ||
          lastName.includes(CustomerStudentData.trim().toLowerCase()) ||
          `${firstName} ${lastName}`.includes(CustomerStudentData.trim().toLowerCase())
        );
      });
    }
    if (fromDate && toDate) {
      const fromdate = new Date(fromDate);
      const todate = new Date(toDate);
      todate.setHours(23, 59, 59, 999);
      filteredResult = filteredResult.filter((item) => {
        const itemDate = item.PurchasedOn ? new Date(item.PurchasedOn.split("T")[0]) : null;
        return itemDate && itemDate >= fromdate && itemDate <= todate;
      });
    }

    setStudentFilteredData(filteredResult);
    setCurrentPage(1);
  };

  const splitSublecture = (sublectureid) => {
    if (!sublectureid) return "NA";
    const datePart = sublectureid.split("-")[1];
    return datePart;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "CustomerStudentData") {
      setCustomerStudentData(value);
    }
  };

  const handelStatusClick = (status) => {
    const filteredStatusData = initialData.filter(
      (item) => item.status === status
    );
    setCustomerStudentData("");
    setFromDate("");
    setToDate("");
    setStudentFilteredData(filteredStatusData);
    setCurrentPage(1);
  };

  const handleExportBtnClick = () => {
    window.open(
      "https://onlinetraining.drgarciabiomagnetism.com/getPaymentStatus_csv",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handelResetBtn = () => {
    setCustomerStudentData("");
    setFromDate("");
    setToDate("");
    setStudentFilteredData(studentProgress);
    setCurrentPage(1);
  };

  const paginatedData = studentfilteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      {callAPILoad && studentProgress.length === 0 ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="filterOption d-flex ">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={10} lg={9} className="p-0">
                    <div className="">
                      <div className="">
                        <div className="form-group">
                          <input
                            type="text"
                            name="CustomerStudentData"
                            className="form-control PaymentInputValue"
                            id="PaymentInputValue"
                            value={CustomerStudentData}
                            onChange={handleChange}
                            placeholder="Search by email ID, name or Student ID"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={2} lg={3}>
                    <div className="">
                      <div className="">
                        <div className="d-flex justify-content-between">
                          <div>
                            <button
                              type="submit"
                              id="filterSubmitBtn"
                              className="btn largescreen filterSubmitBtnStudent"
                            >
                              Search
                            </button>
                            <button
                              type="submit"
                              className="btn smallScreen"
                              id="filterSubmitIcons"
                            >
                              <SiderIcons icons={8} />
                            </button>
                          </div>
                          <div className="ResetBtn">
                            <button
                              className="btn "
                              id="ResetBtnStudent"
                              onClick={handelResetBtn}
                            >
                              <span id="reset">Reset</span>
                              <span id="reseticon">
                                <i className="fa-solid fa-rotate-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          {studentfilteredData.length === 0 ? (
            <div
              className="text-center mt-5"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              <p>No Data Found</p>
            </div>
          ) : (
            <div>
              <div className="" style={{ overflowX: "auto" }}>
                <div className="outputTables table mt-4 mb-2">
                  <table className="col-12">
                    <thead
                      style={{ backgroundColor: "#402F7B", color: "white" }}
                    >
                      <tr id="tableHead">
                        <th style={{ whiteSpace: "nowrap" }}>Order ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Student ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Name</th>
                        <th style={{ whiteSpace: "nowrap" }}>Email ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Modules</th>
                        <th style={{ whiteSpace: "nowrap" }}>Lecture</th>
                        <th style={{ whiteSpace: "nowrap" }}>Sub Lecture</th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          Assessment Session
                        </th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          Assessment Lecturer
                        </th>
                        <th style={{ whiteSpace: "nowrap" }}>Last visit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((item, index) => (
                        <tr key={index} id="tableData">
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.orderid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.userid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {`${item.firstname} ${item.lastname}` || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.student_email || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.sessionid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.lectureid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {splitSublecture(item.sublectureid)}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.assessment_sessionid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.assessment_lecturerid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.updatedat ? timeDateFormate(item.updatedat) : "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {studentProgress.length > 9 && itemsPerPage && (
                <div className="d-flex justify-content-end">
                  <Button
                    variant=""
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="border-0 "
                  >
                    <i className="fa-solid fa-backward-step"></i> Prev
                  </Button>
                  <div className="d-flex text-center align-items-center">
                    {" "}
                    {currentPage} /{" "}
                    {Math.ceil(studentfilteredData.length / itemsPerPage)}
                  </div>

                  <Button
                    variant=""
                    style={{ marginRight: "18px" }}
                    onClick={handleNextPage}
                    className="border-0 "
                    disabled={
                      currentPage ===
                      Math.ceil(studentfilteredData.length / itemsPerPage)
                    }
                  >
                    Next <i className="fa-solid fa-forward-step"></i>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}