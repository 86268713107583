import React, { useEffect, useState, useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { ActionCustomerDetails } from "../../store/actions/actions-server-data";
import "../CustomerDetails/CustomerDetails.css";
import "./ProductDetails.css";
import ProductModel from "../../components/Model/ProductModel";
import SiderIcons from "../../components/SVG Icons/Svg";

export default function ProductDetails() {
  const dispatch = useDispatch();
  const { rServerData: serverData, rLoading: isLoading } = useSelector(
    (state) => state
  );
  const callAPILoad = isLoading?.customerdetailsLoading;
  const callNameAPILoad = isLoading?.customerNameLoading;
  const customerDetailsInfo = serverData?.customerdetails || {};

  const [customerDetailsData, setCustomerDetailsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [customerData, setCustomerData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const totalPages = customerDetailsInfo.totalPages;

  const fetchCustomerDetails = useCallback(async () => {
    setIsFetching(true);
    console.log("customerData", customerData);
    try {
      await dispatch(
        ActionCustomerDetails({
          pageNumber: currentPage,
          searchName: customerData,
        })
      );
    } catch (error) {
      console.error("Error fetching customer details:", error);
    } finally {
      setIsFetching(false);
    }
  }, [currentPage, dispatch]);

  useEffect(() => {
    fetchCustomerDetails();
  }, [currentPage, fetchCustomerDetails]);

  useEffect(() => {
    setCustomerDetailsData(customerDetailsInfo?.productPurchasesInfo || []);
  }, [customerDetailsInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!customerData.trim()) return;
    setIsFetching(true);
    try {
      await dispatch(
        ActionCustomerDetails({
          pageNumber: currentPage,
          searchName: customerData,
        })
      );
    } catch (error) {
      console.error("Error fetching customer filter:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleResetBtn = async (e) => {
    e.preventDefault();
    setCustomerData("");
    setCurrentPage(1);
    await dispatch(ActionCustomerDetails({ pageNumber: 1, searchName: "" }));
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPageRange = (currentPage) => {
    const start = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const end = Math.min(start + 9, totalPages);
    return { start, end };
  };

  const toggleShowMoreActivity = (index) => {
    const purchasedItems = customerDetailsData[index].purchased_items || {};

    const groupedItems = Object.values(purchasedItems).reduce((acc, item) => {
      const key = `${item.orderId}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const selectedItems = Object.entries(groupedItems).map(([key, items]) => ({
      productOrderId: items[0].orderId,
      subItems: items.map((item) => ({
        productId: items.ProductId,
        productName: item.product_name,
        productQty: item.ProductQty,
        productPrice: item.ProductNetPrice,
        totalProductPrice: item.ProductNetPrice,
        purchasedDate: formatDate(item.purchased_date),
      })),
    }));

    const selectedCustomerName = `${customerDetailsData[index].customer_first_name} ${customerDetailsData[index].customer_last_name}`;
    setCustomerName(selectedCustomerName);
    console.log(selectedItems);
    setModalData(selectedItems);
    setShowModal(true);
  };

  if (callAPILoad || isFetching || callNameAPILoad) {
    return (
      <div className="payment_overlay">
        <div className="d-flex align-items-center justify-content-center flex-column">
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin"
            style={{ fontSize: "40px", color: "#000" }}
          />
          <p className="mt-2 payment_loading">Please wait...</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xm={8} sm={10} lg={9} className="p-0">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control PaymentInputValue"
                  id="PaymentInputValue"
                  value={customerData}
                  onChange={(e) => setCustomerData(e.target.value)}
                  placeholder="Search by Customer Name or email address"
                />
              </div>
            </Col>
            <Col xm={2} sm={2} lg={3}>
              <div className="d-flex justify-content-between">
                <div>
                  <button
                    type="submit"
                    id="filterSubmitBtn"
                    className="btn largescreen filterSubmitBtnStudent"
                  >
                    Search
                  </button>
                  <button
                    type="submit"
                    className="btn smallScreen"
                    id="filterSubmitIcons"
                  >
                    <SiderIcons icons={8} />
                  </button>
                </div>
                <div className="ResetBtn">
                  <button
                    className="btn"
                    id="ResetBtnStudent"
                    onClick={handleResetBtn}
                  >
                    <span id="reset">Reset</span>
                    <span id="reseticon">
                      <i className="fa-solid fa-rotate-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      {customerDetailsData.length === 0 ? (
        <div
          className="text-center mt-5"
          style={{ fontSize: "20px", fontWeight: "500" }}
        >
          <p>No Data Found</p>
        </div>
      ) : (
        <div className="outputTables table mt-4" style={{ overflowX: "auto" }}>
          <table className="col-12">
            <thead style={{ backgroundColor: "#402F7B", color: "white" }}>
              <tr id="tableHead">
                <th style={{ whiteSpace: "nowrap" }}>Customer ID</th>
                <th style={{ whiteSpace: "nowrap" }}>Customer Name</th>
                <th style={{ whiteSpace: "nowrap" }}>Customer Email</th>
                <th style={{ whiteSpace: "nowrap" }}>Contact Number</th>
                <th style={{ whiteSpace: "nowrap" }}>Country</th>
                <th style={{ whiteSpace: "nowrap" }}>State</th>
                <th style={{ whiteSpace: "nowrap" }}>City</th>
                <th style={{ whiteSpace: "nowrap" }}>Product info</th>
              </tr>
            </thead>
            <tbody>
              {customerDetailsData &&
                customerDetailsData.map((item, index) => (
                  <tr key={item.customer_id} id="tableData">
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.customer_id || "NA"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {`${item.customer_first_name} ${item.customer_last_name}` ||
                        "NA"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.customer_email || "NA"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.customer_contact_no || "NA"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.customer_country || "NA"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.customer_state || "NA"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.customer_city || "NA"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <button
                        className="btn btn-link text-decoration-none view-more-btn d-flex"
                        onClick={() => toggleShowMoreActivity(index)}
                      >
                        <div>
                          View More
                          <i className="fa-solid fa-circle-info ms-2"></i>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="d-flex flex-wrap justify-content-center mt-3 pagination-container">
        {totalPages > 1 && (
          <Button
            variant="link"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button"
            style={{
              color: "#000",
              border: "none",
              textDecoration: "none",
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Prev
          </Button>
        )}

        <div className="d-flex align-items-center">
          {Array.from(
            {
              length:
                getPageRange(currentPage).end -
                getPageRange(currentPage).start +
                1,
            },
            (_, i) => {
              const page = getPageRange(currentPage).start + i;

              return (
                <Button
                  key={page}
                  variant={page === currentPage ? "primary" : "link"}
                  onClick={() => handlePageChange(page)}
                  disabled={page === currentPage}
                  className={`mx-1 pagination-button ${
                    page === currentPage ? "active" : ""
                  }`}
                  style={{
                    backgroundColor:
                      totalPages > 1 && page === currentPage
                        ? "#402F7B"
                        : "transparent",
                    color: page === currentPage ? "#fff" : "#000",
                    border: "none",
                    textDecoration: "none",
                  }}
                >
                  {totalPages > 1 && page}
                </Button>
              );
            }
          )}
          {totalPages >= 10 && currentPage <= totalPages - 10 && (
            <span>...</span>
          )}
          {totalPages > 10 && (
            <Button
              variant="link"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
              className="pagination-button"
              style={{
                color: "#000",
                border: "none",
                textDecoration: "none",
              }}
            >
              {currentPage <= totalPages - 10 ? totalPages : ""}
            </Button>
          )}
        </div>
        {totalPages > 1 && (
          <Button
            variant="link"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button"
            style={{
              color: "#000",
              border: "none",
              textDecoration: "none",
            }}
          >
            Next <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        )}
      </div>

      {showModal && (
        <ProductModel
          show={showModal}
          handleClose={() => setShowModal(false)}
          modalData={modalData}
          CustomerName={customerName}
        />
      )}
    </div>
  );
}
