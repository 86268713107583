const SiderIcons = ({ icons, active }) => {
  const iconStyle = {
    fill: active ? "#402F7B" : "#ffffff",
  };

  switch (icons) {
    case 1:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          style={iconStyle}
        >
          <g id="__TEMP__SVG__" transform="translate(-24 -24)">
            <path
              id="Path_14112"
              data-name="Path 14112"
              d="M25.111,35.111h6.667A1.111,1.111,0,0,0,32.889,34V25.111A1.111,1.111,0,0,0,31.778,24H25.111A1.111,1.111,0,0,0,24,25.111V34A1.111,1.111,0,0,0,25.111,35.111ZM24,42.889A1.111,1.111,0,0,0,25.111,44h6.667a1.111,1.111,0,0,0,1.111-1.111V38.444a1.111,1.111,0,0,0-1.111-1.111H25.111A1.111,1.111,0,0,0,24,38.444Zm11.111,0A1.111,1.111,0,0,0,36.222,44h6.667A1.111,1.111,0,0,0,44,42.889V35.111A1.111,1.111,0,0,0,42.889,34H36.222a1.111,1.111,0,0,0-1.111,1.111Zm1.111-11.111h6.667A1.111,1.111,0,0,0,44,30.667V25.111A1.111,1.111,0,0,0,42.889,24H36.222a1.111,1.111,0,0,0-1.111,1.111v5.556A1.111,1.111,0,0,0,36.222,31.778Z"
            />
          </g>
        </svg>
      );

    case 2:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          style={iconStyle}
        >
          <g id="cube" transform="translate(0 0)">
            <g
              id="Group_31769"
              data-name="Group 31769"
              transform="translate(10.379 3.219)"
            >
              <g id="Group_31768" data-name="Group 31768">
                <path
                  id="Path_14085"
                  data-name="Path 14085"
                  d="M271,87.762V92.6l4.986-2.493V85.269Z"
                  transform="translate(-271 -85.269)"
                />
              </g>
            </g>
            <g
              id="Group_31771"
              data-name="Group 31771"
              transform="translate(5.818 11.618)"
            >
              <g id="Group_31770" data-name="Group 31770">
                <path
                  id="Path_14086"
                  data-name="Path 14086"
                  d="M151,312.142v6.513l3.74-2.15v-6.233Z"
                  transform="translate(-151 -310.272)"
                />
              </g>
            </g>
            <g
              id="Group_31773"
              data-name="Group 31773"
              transform="translate(4.634 3.219)"
            >
              <g id="Group_31772" data-name="Group 31772">
                <path
                  id="Path_14087"
                  data-name="Path 14087"
                  d="M121,85.272v4.839l4.986,2.493V87.765Z"
                  transform="translate(-121 -85.272)"
                />
              </g>
            </g>
            <g
              id="Group_31775"
              data-name="Group 31775"
              transform="translate(0.665 8.686)"
            >
              <g id="Group_31774" data-name="Group 31774">
                <path
                  id="Path_14088"
                  data-name="Path 14088"
                  d="M19.593,226,16,227.8l4.986,2.493,3.593-1.8Z"
                  transform="translate(-16 -226)"
                />
              </g>
            </g>
            <g
              id="Group_31777"
              data-name="Group 31777"
              transform="translate(10.442 11.618)"
            >
              <g id="Group_31776" data-name="Group 31776">
                <path
                  id="Path_14089"
                  data-name="Path 14089"
                  d="M271,310.272v6.233l3.74,2.15v-6.513Z"
                  transform="translate(-271 -310.272)"
                />
              </g>
            </g>
            <g
              id="Group_31779"
              data-name="Group 31779"
              transform="translate(10.756 8.686)"
            >
              <g id="Group_31778" data-name="Group 31778">
                <path
                  id="Path_14090"
                  data-name="Path 14090"
                  d="M294.531,226l-4.986,2.493,3.593,1.8,4.986-2.493Z"
                  transform="translate(-289.545 -225.998)"
                />
              </g>
            </g>
            <g
              id="Group_31781"
              data-name="Group 31781"
              transform="translate(0 10.994)"
            >
              <g id="Group_31780" data-name="Group 31780">
                <path
                  id="Path_14091"
                  data-name="Path 14091"
                  d="M0,295.272v5.848a.623.623,0,0,0,.345.558l4.642,2.6v-6.513Z"
                  transform="translate(0 -295.272)"
                />
              </g>
            </g>
            <g
              id="Group_31783"
              data-name="Group 31783"
              transform="translate(15.014 10.994)"
            >
              <g id="Group_31782" data-name="Group 31782">
                <path
                  id="Path_14092"
                  data-name="Path 14092"
                  d="M392,297.762v6.513l4.642-2.6a.624.624,0,0,0,.345-.558v-5.848Z"
                  transform="translate(-392 -295.269)"
                />
              </g>
            </g>
            <g
              id="Group_31785"
              data-name="Group 31785"
              transform="translate(5.014 0)"
            >
              <g id="Group_31784" data-name="Group 31784">
                <path
                  id="Path_14093"
                  data-name="Path 14093"
                  d="M141.265.066a.624.624,0,0,0-.558,0L136,2.461l4.986,2.493,4.986-2.493Z"
                  transform="translate(-136 0)"
                />
              </g>
            </g>
          </g>
        </svg>
      );

    case 3:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          className=""
          style={iconStyle}
        >
          <g id="status" transform="translate(-0.5 -0.5)">
            <path
              id="Path_14094"
              data-name="Path 14094"
              d="M14.594,3.068l-3.8,3.8a4.626,4.626,0,0,1,.88,2.156.984.984,0,0,1,.231-.037h5.008a.983.983,0,0,1,.134.022,9.96,9.96,0,0,0-2.452-5.941Z"
              transform="translate(3.453 0.862)"
            />
            <path
              id="Path_14095"
              data-name="Path 14095"
              d="M.5,10.516A10.013,10.013,0,0,0,9.87,20.5a.992.992,0,0,1-.022-.134V15.357a.992.992,0,0,1,.037-.231A4.662,4.662,0,1,1,13.3,6.786l3.8-3.8A10,10,0,0,0,.5,10.516Z"
              transform="translate(0 0)"
            />
            <path
              id="Path_14096"
              data-name="Path 14096"
              d="M17.691,8.509H12.683a.984.984,0,0,1-.231-.037,4.654,4.654,0,0,1-3.98,3.98.992.992,0,0,1,.037.231v5.008a.992.992,0,0,1-.022.134,10.006,10.006,0,0,0,9.337-9.338.983.983,0,0,1-.134.022Z"
              transform="translate(2.675 2.675)"
            />
          </g>
        </svg>
      );

    case 4:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.505"
          height="20"
          viewBox="0 0 20.505 20"
          style={iconStyle}
        >
          <g id="Registration" transform="translate(-1.1 -2)">
            <path
              id="Path_14122"
              data-name="Path 14122"
              d="M22.815,2a3.8,3.8,0,1,0,3.8,3.8A3.8,3.8,0,0,0,22.815,2ZM24.5,5.639l-1.856,1.5a.622.622,0,0,1-.88-.1l-.729-.93a.623.623,0,1,1,.98-.769l.34.433,1.365-1.1a.623.623,0,1,1,.781.97Z"
              transform="translate(-5.517)"
            />
            <path
              id="Path_14123"
              data-name="Path 14123"
              d="M20.636,18.753H18.969V11.36A4.831,4.831,0,0,1,12.7,5.32H3.377c-.007,0-.021.007-.028.007A2.27,2.27,0,0,0,1.1,7.59V8.621a.972.972,0,0,0,.969.969H4.4V20.746a2.285,2.285,0,0,0,.74,1.682,2.31,2.31,0,0,0,1.522.6H19.328a2.279,2.279,0,0,0,2.277-2.277V19.721A.972.972,0,0,0,20.636,18.753ZM4.4,8.344H2.346v-.75a1.027,1.027,0,0,1,2.054,0v.75ZM8.546,10.69h4.443a.623.623,0,1,1,0,1.246H8.546a.623.623,0,0,1,0-1.246Zm-.622,3.251a.623.623,0,0,1,.623-.623h3.042a.623.623,0,1,1,0,1.246H8.547a.622.622,0,0,1-.623-.623Zm12.437,6.806a1.03,1.03,0,0,1-1.03,1.03H8.694A3.817,3.817,0,0,0,8.941,20h9.4l.01,0h2.006v.75Z"
              transform="translate(0 -1.022)"
            />
          </g>
        </svg>
      );

    case 5:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.105"
          height="20"
          viewBox="0 0 22.105 20"
          style={iconStyle}
        >
          <g
            id="online-education_1_"
            data-name="online-education (1)"
            transform="translate(-3 -5)"
          >
            <path
              id="Path_14124"
              data-name="Path 14124"
              d="M23.105,34.632H21V32.526A.526.526,0,0,0,20.474,32H15.211a.526.526,0,0,0-.526.526v2.105H12.579A1.581,1.581,0,0,0,11,36.211v1.053a.526.526,0,0,0,.526.526H24.158a.526.526,0,0,0,.526-.526V36.211a1.581,1.581,0,0,0-1.579-1.579Z"
              transform="translate(-3.789 -12.789)"
            />
            <path
              id="Path_14125"
              data-name="Path 14125"
              d="M37.716,23.031a.316.316,0,0,0-.316.316,1.623,1.623,0,0,0,.315.49,1.633,1.633,0,0,0,.316-.5A.316.316,0,0,0,37.716,23.031Z"
              transform="translate(-16.295 -8.541)"
            />
            <path
              id="Path_14126"
              data-name="Path 14126"
              d="M23.5,13.493l-6.1-2.348a.214.214,0,0,0-.076-.014.222.222,0,0,0-.077.014l-6.1,2.348a.212.212,0,0,0,.014.4l6.1,1.878a.2.2,0,0,0,.124,0h0l6.1-1.878a.212.212,0,0,0,.014-.4Z"
              transform="translate(-3.789 -2.904)"
            />
            <path
              id="Path_14127"
              data-name="Path 14127"
              d="M19.211,20.853a1.248,1.248,0,0,1-.372-.057L15,19.616v2.092a.534.534,0,0,0,.365.5l3.512,1.167a1.025,1.025,0,0,0,.665,0l3.518-1.169a.532.532,0,0,0,.361-.5V19.616L19.583,20.8A1.248,1.248,0,0,1,19.211,20.853Z"
              transform="translate(-5.684 -6.923)"
            />
            <path
              id="Path_14128"
              data-name="Path 14128"
              d="M23.526,5H4.579A1.581,1.581,0,0,0,3,6.579V18.684a1.581,1.581,0,0,0,1.579,1.579H23.526a1.581,1.581,0,0,0,1.579-1.579V6.579A1.581,1.581,0,0,0,23.526,5ZM21.783,16.451a.527.527,0,0,1-.725,0c-.3-.287-1.006-1.021-1.006-1.645a1.37,1.37,0,0,1,.842-1.262V11.658l-.244-.122A1.26,1.26,0,0,1,20,12l-1.212.373v2.416a1.586,1.586,0,0,1-1.077,1.5l-3.52,1.17a2.071,2.071,0,0,1-1.335,0L9.345,16.284a1.588,1.588,0,0,1-1.081-1.5V12.369L7.051,12a1.258,1.258,0,0,1-.893-1.209,1.271,1.271,0,0,1,.811-1.181l6.1-2.348a1.267,1.267,0,0,1,.907,0l6.1,2.348a1.27,1.27,0,0,1,.763.85l.518.257a1.049,1.049,0,0,1,.582.945v1.885a1.37,1.37,0,0,1,.842,1.262C22.789,15.43,22.086,16.164,21.783,16.451Z"
            />
          </g>
        </svg>
      );
    case 6:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.286"
          height="20"
          viewBox="0 0 19.286 20"
          style={iconStyle}
        >
          <g id="personal-profile" transform="translate(-5 -4)">
            <path
              id="Path_14097"
              data-name="Path 14097"
              d="M14.714,8.277h5.714a.717.717,0,0,0,.714-.715V6.133a.717.717,0,0,0-.714-.715H19.321a1.789,1.789,0,0,0-3.5,0H14.714A.717.717,0,0,0,14,6.133V7.562a.717.717,0,0,0,.714.715Z"
              transform="translate(-5.786)"
            />
            <path
              id="Path_14098"
              data-name="Path 14098"
              d="M21.662,26.6a1.31,1.31,0,0,0,0-2.619A1.31,1.31,0,0,0,21.662,26.6Z"
              transform="translate(-9.876 -12.844)"
            />
            <path
              id="Path_14099"
              data-name="Path 14099"
              d="M18.357,36.8h3.571a.358.358,0,0,0,.357-.357v-.954a2.143,2.143,0,0,0-4.286,0v.954a.358.358,0,0,0,.357.357Z"
              transform="translate(-8.357 -18.874)"
            />
            <path
              id="Path_14100"
              data-name="Path 14100"
              d="M16.429,10.949h-.357v1.072a1.429,1.429,0,0,1-1.429,1.429H8.929A1.429,1.429,0,0,1,7.5,12.021V10.949A2.194,2.194,0,0,0,5,13.093S5,26.315,5,26.315a2.145,2.145,0,0,0,2.143,2.144h9.286a2.145,2.145,0,0,0,2.143-2.144V13.093a2.145,2.145,0,0,0-2.143-2.144Zm-7.5,10.124A2.854,2.854,0,0,1,10.546,18.5a2.025,2.025,0,1,1,2.479,0,2.854,2.854,0,0,1,1.618,2.569v.954A1.075,1.075,0,0,1,13.571,23.1H10a1.075,1.075,0,0,1-1.071-1.072Zm6.429,5.242H8.214a.357.357,0,0,1,0-.715h7.143A.357.357,0,0,1,15.357,26.315Zm0-1.429H8.214a.357.357,0,0,1,0-.715h7.143A.357.357,0,0,1,15.357,24.885Z"
              transform="translate(0 -4.459)"
            />
            <path
              id="Path_14101"
              data-name="Path 14101"
              d="M46.333,53.935a3.953,3.953,0,0,0,.868,1.3.362.362,0,0,0,.507,0,4.053,4.053,0,0,0,1.157-2.484H46.04A4.139,4.139,0,0,0,46.333,53.935Z"
              transform="translate(-26.383 -31.343)"
            />
            <path
              id="Path_14102"
              data-name="Path 14102"
              d="M49.571,16.481h-.714c-.019-.652.14-1.594-.418-2.083A1.436,1.436,0,0,0,46,15.409S46,17.2,46,17.2h3.571a.358.358,0,0,1,.357.357v3.573a.357.357,0,1,0,.714,0V17.553A1.075,1.075,0,0,0,49.571,16.481Z"
              transform="translate(-26.357 -6.418)"
            />
            <path
              id="Path_14103"
              data-name="Path 14103"
              d="M46,24.98h2.857v9.2H46Z"
              transform="translate(-26.357 -13.487)"
            />
          </g>
        </svg>
      );
    case 7:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="22"
          viewBox="0 0 512 512"
          width="22"
          style={iconStyle}
        >
          <g>
            <path d="m395.24 175.667c.738 19.881 29.269 19.866 30 0v-61.844l-30 10.965z" />
            <path d="m96.607 85.481 154.189 56.395c3.327 1.217 6.974 1.217 10.302.002l154.292-56.395c13.05-4.755 13.045-23.425.001-28.177l-154.241-56.393c-3.326-1.217-6.975-1.217-10.301 0l-154.24 56.393c-13.048 4.754-13.043 23.423-.002 28.175z" />
            <path d="m152.64 327.227c0-28.055-22.825-50.88-50.88-50.88-67.407 2.558-67.388 99.212.001 101.76 28.054 0 50.879-22.825 50.879-50.88z" />
            <path d="m461.121 327.227c0-28.055-22.825-50.88-50.88-50.88-67.407 2.558-67.388 99.212.001 101.76 28.054 0 50.879-22.825 50.879-50.88z" />
            <path d="m205.12 327.227c0 28.055 22.825 50.88 50.88 50.88 67.407-2.558 67.389-99.212-.001-101.76-28.054 0-50.879 22.825-50.879 50.88z" />
            <path d="m410.24 378.107c-20.089 0-38.836 5.853-54.628 15.941 19.962 23.072 32.045 53.067 32.045 85.818v32.134h109.343c8.284 0 15-6.716 15-15v-17.134c0-56.11-45.649-101.759-101.76-101.759z" />
            <path d="m255.147 378.107c-56.524 0-102.51 45.649-102.51 101.76v32.133h205.021v-32.134c-.001-56.11-45.987-101.759-102.511-101.759z" />
            <path d="m101.76 378.107c-56.111 0-101.76 45.649-101.76 101.759v17.134c0 8.284 6.716 15 15 15h107.637v-32.134c0-33.078 12.326-63.346 32.646-86.509-15.559-9.661-33.9-15.25-53.523-15.25z" />
            <path d="m351.333 194.49v-53.653l-79.936 29.217c-9.904 3.645-21.021 3.645-30.917-.009l-79.813-29.191v53.652c0 6.298 3.934 11.925 9.848 14.087l80.282 29.362c3.326 1.216 6.975 1.216 10.301.001l80.384-29.377c5.915-2.162 9.851-7.79 9.851-14.089z" />
          </g>
        </svg>
      );
    case 8:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g id="search" transform="translate(-31.965 -12.226)">
            <g
              id="Group_31933"
              data-name="Group 31933"
              transform="translate(31.965 12.226)"
            >
              <path
                id="Path_16642"
                data-name="Path 16642"
                d="M50.15,28.615a10.164,10.164,0,1,0-1.793,1.792l.055.057L53.8,35.853a1.271,1.271,0,1,0,1.8-1.8l-5.387-5.388-.057-.053ZM47.514,16.994a7.619,7.619,0,1,1-10.772,0,7.622,7.622,0,0,1,10.772,0Z"
                transform="translate(-31.965 -12.226)"
              />
            </g>
          </g>
        </svg>
      );
    case 9:
      return (
        <svg
          id="download"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="24"
          viewBox="0 0 26 24"
        >
          <path
            id="Path_16643"
            data-name="Path 16643"
            d="M20,21a1.014,1.014,0,1,0-.3.7A.961.961,0,0,0,20,21Zm4,0a1.014,1.014,0,1,0-.3.7A.961.961,0,0,0,24,21Zm2-3.5v5A1.494,1.494,0,0,1,24.5,24H1.5a1.447,1.447,0,0,1-1.062-.437A1.447,1.447,0,0,1,0,22.5v-5a1.447,1.447,0,0,1,.437-1.062A1.447,1.447,0,0,1,1.5,16H8.766l2.109,2.125a3.018,3.018,0,0,0,4.25,0L17.25,16H24.5A1.494,1.494,0,0,1,26,17.5ZM20.922,8.609A.9.9,0,0,1,20.7,9.7l-7,7a.981.981,0,0,1-1.406,0l-7-7a.9.9,0,0,1-.219-1.094A.934.934,0,0,1,6,8h4V1a.961.961,0,0,1,.3-.7A.961.961,0,0,1,11,0h4a.961.961,0,0,1,.7.3A.961.961,0,0,1,16,1V8h4A.934.934,0,0,1,20.922,8.609Z"
            transform="translate(0)"
          />
        </svg>
      );
    case 10:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27.743"
          height="15.721"
          viewBox="0 0 27.743 15.721"
        >
          <g id="filter" transform="translate(-6.08 -45.6)">
            <path
              id="Path_14129"
              data-name="Path 14129"
              d="M32.435,48.374H7.467a1.387,1.387,0,0,1,0-2.774H32.435a1.387,1.387,0,1,1,0,2.774Z"
            />
            <path
              id="Path_14130"
              data-name="Path 14130"
              d="M53.588,90.934H37.867a1.387,1.387,0,1,1,0-2.774H53.588a1.387,1.387,0,1,1,0,2.774Z"
              transform="translate(-25.776 -36.087)"
            />
            <path
              id="Path_14131"
              data-name="Path 14131"
              d="M78.971,133.494H74.347a1.387,1.387,0,1,1,0-2.774h4.624a1.387,1.387,0,1,1,0,2.774Z"
              transform="translate(-56.708 -72.173)"
            />
          </g>
        </svg>
      );
    case 11:
      return (
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="24" 
          viewBox="0 0 25 25"
          style={iconStyle}
        >
          <g>
            <g>
              <g>
                <g enable-background="new">
                  <g>
                    <path d="m8.4 8.9v13.7l-8.4-4.6v-13.7z" />
                  </g>
                  <g>
                    <path d="m21.2 6.7-12.5 1.7-8.1-4.4 12.4-1.8z" />
                  </g>
                  <g>
                    <path d="m21.8 7.2v5.4c-.5-.2-1.1-.2-1.6-.2-3.1 0-5.6 2.5-5.6 5.6 0 1.5.6 2.8 1.5 3.8l-7.1 1v-13.8z" />
                  </g>
                </g>
              </g>
              <path d="m20.1 13c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9zm0 2c.4 0 .6.3.6.6 0 .4-.3.6-.6.6-.4 0-.6-.3-.6-.6s.3-.6.6-.6zm1 5.8h-1.9v-.3c.1 0 .2-.1.3-.1 0-.1.1-.2.1-.4v-2.1c0-.2 0-.3-.1-.3-.1-.1-.2-.1-.4-.1v-.6l1.6-.1v3.2c0 .2 0 .3.1.4s.1.1.3.1z" />
            </g>
          </g>
        </svg>
      );
  }
};
export default SiderIcons;
