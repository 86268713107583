import React, { useEffect, useState } from "react";
import "./PaymentStatus.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import PaymetFailModel from "../../components/Model/PaymetFailModel";
import { useDispatch, useSelector } from "react-redux";
import ExportBtnIcons from "../../Assets/Admin LMS Image/Icons/page-csv.svg";
import { ActionPaymentStatus } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import SiderIcons from "../../components/SVG Icons/Svg";

export default function PaymentStatus() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const ServerData = mystate?.rServerData;
  const isLoading = mystate?.rLoading;
  const callAPILoad = isLoading?.PaymentStatusLoading;
  const userAccessToken = ServerData?.userAccessToken || null;
  const PaymentStatus = ServerData?.PaymentStatus?.data || [];

  const itemsPerPage = 9;

  const [paymentfilteredData, setPaymentFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [Reason1, setReason1] = useState("");
  const [Reason2, setReason2] = useState("");
  const [Reason3, setReason3] = useState("");
  const [CustomerPaymentData, setCustomerPaymentData] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fromDatePicker, setFromDatePicker] = useState(null);
  const [toDatePicker, setToDatePicker] = useState(null);
  const [isDropdownToggle, setIsDropdownToggle] = useState(true);

  useEffect(() => {
    dispatch(ActionPaymentStatus({ token: userAccessToken }));
  }, [dispatch]);

  useEffect(() => {
    const reversedData = [...PaymentStatus].reverse();
    setInitialData(reversedData);
    setPaymentFilteredData(reversedData);
  }, [PaymentStatus]);

  useEffect(() => {
    if (fromDatePicker && toDatePicker && fromDate) {
      toDatePicker.set("minDate", fromDate);
    }
  }, [fromDate, fromDatePicker, toDatePicker]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsDropdownToggle(false);
      } else {
        setIsDropdownToggle(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => setShowModal(false);

  const timeDateFormate = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  const DateFormate = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(paymentfilteredData.length / itemsPerPage)
      )
    );
  };

  const ShowTheFailedReason = (item) => {
    var reason1 = "2- This transaction has been declined.";
    var reason2 = "P - AVS not applicable for this transaction";
    var reason3 = "M - CVV2/CVC2 Match";
    setReason1(reason1);
    setReason2(reason2);
    setReason3(reason3);
    setShowModal(true);
  };

  // const filterData = () => {

  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredResult = initialData;

    if (CustomerPaymentData) {
      filteredResult = filteredResult.filter((item) => {
        const customerEmail = item.CustomerEmail?.toLowerCase() || "";
        const firstName = item.Firstname?.toLowerCase() || "";
        const lastName = item.Lastname?.toLowerCase() || "";
        const userId = item.userid;
        const OrderId = item.orderid;

        return (
          customerEmail.includes(CustomerPaymentData.trim().toLowerCase()) ||
          userId == CustomerPaymentData.trim() ||
          OrderId == CustomerPaymentData.trim() ||
          firstName.includes(CustomerPaymentData.trim().toLowerCase()) ||
          lastName.includes(CustomerPaymentData.trim().toLowerCase()) ||
          `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(
            CustomerPaymentData.trim().toLowerCase()
          )
        );
      });
    }
    if (fromDate && toDate) {
      const fromdate = new Date(fromDate);
      const todate = new Date(toDate);
      todate.setHours(23, 59, 59, 999);
      filteredResult = filteredResult.filter((item) => {
        let itemDate;
        if (item.PurchasedOn == null) {
          itemDate = 0;
        } else {
          itemDate = new Date(item.PurchasedOn.split("T")[0]);
        }
        return itemDate >= fromdate && itemDate <= todate;
      });
    }

    setPaymentFilteredData(filteredResult);
    setCurrentPage(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "CustomerPaymentData") {
      setCustomerPaymentData(value);
    }
  };

  const handelStatusClick = (status) => {
    const filteredStatusData = initialData.filter(
      (item) => item.status === status
    );
    setCustomerPaymentData("");
    setFromDate("");
    setToDate("");
    setPaymentFilteredData(filteredStatusData);
    setCurrentPage(1);
  };

  const handleExportBtnClick = () => {
    window.open(
      "https://onlinetraining.drgarciabiomagnetism.com/getPaymentStatus_csv",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handelResetBtn = () => {
    setCustomerPaymentData("");
    setFromDate("");
    setToDate("");
    setPaymentFilteredData(PaymentStatus);
    setCurrentPage(1);
  };

  const paginatedData = paymentfilteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(paymentfilteredData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPageRange = (currentPage) => {
    const start = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const end = Math.min(start + 9, totalPages);
    return { start, end };
  };

  return (
    <div>
      {callAPILoad && PaymentStatus.length === 0 ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="filterOption d-flex ">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={3} lg={4} className="p-0">
                    <div className="">
                      <div className="">
                        <div className="form-group">
                          <input
                            type="text"
                            name="CustomerPaymentData"
                            className="form-control PaymentInputValue"
                            id="PaymentInputValue"
                            value={CustomerPaymentData}
                            onChange={handleChange}
                            placeholder="Search by email ID, name or customer ID"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={9} lg={8}>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="me-3 position-relative">
                              <Flatpickr
                                className="form-control"
                                placeholder="Ordering From"
                                options={{
                                  dateFormat: "Y-m-d",
                                  altFormat: "d M Y",
                                  altInput: true,
                                  allowInput: true,
                                  onReady: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    setFromDatePicker(instance);
                                  },
                                }}
                                value={fromDate}
                                onChange={([date]) => {
                                  setFromDate(date);
                                }}
                              />
                            </div>

                            <div className="position-relative me-3">
                              <Flatpickr
                                className="form-control"
                                placeholder="Ordering To"
                                options={{
                                  dateFormat: "Y-m-d",
                                  altFormat: "d M Y",
                                  altInput: true,
                                  allowInput: true,
                                  onReady: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    setToDatePicker(instance);
                                  },
                                }}
                                value={toDate}
                                onChange={([date]) => {
                                  setToDate(date);
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              id="filterSubmitBtn"
                              className="btn largescreen"
                            >
                              Search
                            </button>
                            {/* <button
                            type="submit"
                            className="btn smallScreen"
                            id="filterSubmitIcons"
                          >
                            <SiderIcons
                              icons={8}
                            />
                          </button> */}
                          </div>
                          <div className="ResetBtn">
                            <button
                              className="btn "
                              id="ResetBtn"
                              onClick={handelResetBtn}
                            >
                              <span id="reset">Reset</span>
                              <span id="reseticon">
                                <i class="fa-solid fa-rotate-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between ms-3">
                        <div className="exportPaymentStatusBt me-2">
                          <button
                            className="exportPaymentStatusBtn px-3 "
                            onClick={handleExportBtnClick}
                          >
                            <span className="largescreen">
                              <img
                                src={ExportBtnIcons}
                                alt="Export Icon"
                                className="pe-2"
                                width="18"
                              />
                              Export
                            </span>
                            <span className="smallScreen">
                              <SiderIcons icons={9} />
                            </span>
                          </button>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className={`btn ${
                                isDropdownToggle ? "dropdown-toggle" : ""
                              }`}
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="LargeScreenFilter">Filter</span>
                              <span className="smallScreen">
                                <SiderIcons icons={10} />
                              </span>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li onClick={() => handelStatusClick("success")}>
                                <a className="dropdown-item">Completed</a>
                              </li>
                              <li onClick={() => handelStatusClick("failed")}>
                                <a className="dropdown-item">Failed</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          {paymentfilteredData.length === 0 ? (
            <div
              className="text-center mt-5"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              <p>No Data Found</p>
            </div>
          ) : (
            <div>
              <div className="" style={{ overflowX: "auto" }}>
                <div className="outputTables table mt-4 mb-2">
                  <table className="col-12">
                    <thead
                      style={{ backgroundColor: "#402F7B", color: "white" }}
                    >
                      <tr id="tableHead">
                        <th style={{ whiteSpace: "nowrap" }}>Ordering Date</th>
                        <th style={{ whiteSpace: "nowrap" }}>Order ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Customer ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Name</th>
                        <th style={{ whiteSpace: "nowrap" }}>Email ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Expire Date</th>
                        <th style={{ whiteSpace: "nowrap" }}>Status</th>
                        {/* <th style={{ whiteSpace: "nowrap" }}></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((item, index) => (
                        <tr key={index} id="tableData">
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.PurchasedOn == "NA"
                              ? "NA"
                              : (item.PurchasedOn &&
                                  timeDateFormate(item.PurchasedOn)) ||
                                "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.orderid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.userId || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.Firstname + " " + item.Lastname || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.CustomerEmail || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {(item.expired_at &&
                              item.status === "success" &&
                              DateFormate(item.expired_at)) ||
                              "NA"}
                          </td>
                          <td
                            style={{
                              color:
                                item.status === "success" ? "green" : "red",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.status === "success" ? "Completed" : "Failed"}

                            {item.status !== "success" && (
                              <button
                                type="button"
                                className=" PaymentFailedBtn ms-3"
                                onClick={() => ShowTheFailedReason(item)}
                              >
                                <i className="fa-solid fa-circle-info pt-1 PaymentFailedInfo"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {showModal && (
                  <PaymetFailModel
                    show={showModal}
                    handleClose={handleClose}
                    reason1={Reason1}
                    reason2={Reason2}
                    reason3={Reason3}
                  />
                )}
              </div>
              <div className="d-flex justify-content-center">
                {PaymentStatus.length > 9 && itemsPerPage && (
                  <div className="d-flex align-items-center">
                    {/* Previous Button */}
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        <i className="fa-solid fa-backward-step"></i> Prev
                      </Button>
                    )}

                    {/* Page Numbers */}
                    <div className="d-flex text-center align-items-center">
                      {Array.from(
                        {
                          length:
                            getPageRange(currentPage).end -
                            getPageRange(currentPage).start +
                            1,
                        },
                        (_, i) => {
                          const page = getPageRange(currentPage).start + i;

                          return (
                            <Button
                              key={page}
                              variant={
                                page === currentPage ? "primary" : "link"
                              }
                              onClick={() => handlePageChange(page)}
                              disabled={page === currentPage}
                              className={`mx-1 pagination-button ${
                                page === currentPage ? "active" : ""
                              }`}
                              style={{
                                backgroundColor:
                                  totalPages > 1 && page === currentPage
                                    ? "#402F7B"
                                    : "transparent",
                                color: page === currentPage ? "#fff" : "#000",
                                border: "none",
                                textDecoration: "none",
                              }}
                            >
                              {totalPages > 1 && page}
                            </Button>
                          );
                        }
                      )}

                      {/* Ellipsis */}
                      {totalPages >= 10 && currentPage <= Math.floor(totalPages / 10) * 10 && (
                        <span>...</span>
                      )}

                      {/* Last Page Button */}
                      {totalPages > 10 && (
                        <Button
                          variant="link"
                          onClick={() => handlePageChange(totalPages)}
                          disabled={currentPage === totalPages}
                          className="pagination-button"
                          style={{
                            color: "#000",
                            border: "none",
                            textDecoration: "none",
                          }}
                        >
                          {currentPage <= Math.floor(totalPages / 10) * 10 ? totalPages : ""}
                        </Button>
                      )}
                    </div>
                    {/* Next Button */}
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        Next <i className="fa-solid fa-forward-step"></i>
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
