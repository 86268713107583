import React, { useState } from "react";
import MainLogo from "../../Assets/Admin LMS Image/DrGarciaLoginLogo.png";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ActionUpdatePassword } from "../../store/actions/actions-user";
import { ActionOpenNotification } from "../../store/actions/actions-utils-data";
import { NotificationContainer } from "react-notifications";

function PasswordResetScreen() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);

  const userEmail = mystate.router?.location?.state?.data?.Admin?.email || null;
  const genPassword = mystate.router?.location?.state?.oldpassword || null;

  const [resetValues, setResetValues] = useState({
    gen_password: "",
    new_password: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorPassword, setErrorPassword] = useState({
    gen_password: "",
    Password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResetValues({
      ...resetValues,
      [name]: value,
    });
  };

  const handleResetSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!resetValues.gen_password) {
      newErrors.gen_password = "Generated Password Required";
    }else if (resetValues.gen_password !== genPassword){
      newErrors.gen_password = "Generated Password is invalued";
    }

    if (!resetValues.new_password) {
      newErrors.new_password = "New Password Required";
    } else if (resetValues.new_password.length <= 8) {
      newErrors.new_password = "New Password should be greater than 8 characters";
    } else if (resetValues.new_password === resetValues.gen_password) {
      newErrors.new_password = "New Password should not be the same as the generated password";
    }

    setErrorPassword(newErrors);

    if (Object.keys(newErrors).length === 0 && resetValues.gen_password === genPassword) {
      const param = {
        email: userEmail,
        password: resetValues.new_password,
        confirm_password: resetValues.new_password,
        role_id: "1"
      };
      dispatch(ActionUpdatePassword(param));
    } else {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 2500);
    }
  };

  return (
    <div id="ForgotPassword">
      <div className="px-md-5 mx-md-5 ">
        <div className="px-md-5 mx-5 ">
          <div className=" text-center">
            <div className="mx-lg-5 px-lg-5 mx-md-0 px-md-0">
              <div className="forgotPwdTitle mt-4 text-uppercase">WELCOME</div>
              <div className="mx-lg-5 px-lg-5 mx-md-0 px-md-0">
                <div className="emailForms">
                  <div className="formInput ">
                    <Form onSubmit={(values) => handleResetSubmit(values)}>
                      <Form.Group
                        className="text-start mb-3"
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label id="ForgotPwdlabel">Email ID</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={userEmail}
                          className="ForgotPwdInput"
                          placeholder="Enter your Email ID"
                        />
                      </Form.Group>
                      <Form.Group
                        className="text-start mb-4"
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label id="ForgotPwdlabel">
                          Generated Password
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="ForgotPwdInput"
                          placeholder="Enter your Generated Password"
                          name="gen_password"
                          value={resetValues.gen_password}
                          onChange={handleChange}
                        />
                        {errorPassword.gen_password && (
                          <p className="text-danger text-start position-absolute">
                            {errorPassword.gen_password}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="text-start mb-4"
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label id="ForgotPwdlabel">
                          New Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          className="ForgotPwdInput"
                          placeholder="Enter your New Password"
                          name="new_password"
                          value={resetValues.new_password}
                          onChange={handleChange}
                        />
                        {errorPassword.new_password && (
                          <p className="text-danger text-start position-absolute">
                            {errorPassword.new_password}
                          </p>
                        )}
                      </Form.Group>
                      <Button type="submit" className="mt-3" disabled={isDisabled}>
                        Reset Password
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer/>
    </div>
  );
}
export default PasswordResetScreen;
