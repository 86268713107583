import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ProductModel = ({ show, handleClose, modalData, CustomerName }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Product Information of{" "}
          <span className="fw-bold" style={{ fontSize: "30px" }}>
            {CustomerName}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
        {modalData.map((item, index) => (
          <div
            key={`${item.productId}-${index}`}
            className=" my-3 accordion accordion-flush p-1"
            id="accordionFlushExample"
            style={{ border: "none",borderRadius:"10px", boxShadow: "0 0px 1px black" }}
          >
            <div
              className="card-title ms-4 m-3 d-flex justify-content-between"
              onClick={() => toggleAccordion(index)}
              style={{ cursor: "pointer" }}
            >
              <h5>Order Id: {item.productOrderId}</h5>
              <div
                className="me-3"
                style={{
                  transition: "transform 2s ease",
                }}
              >
               {openIndex === index ?  <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
              </div>
            </div>
            <div
              className="accordion-content"
            >
              {openIndex === index && (
                <div className="card-body mt-0 pt-0">
                  {item.subItems.map((subItem, subIndex) => (
                    <div
                      key={`${item.productId}-${subIndex}`}
                      className="ms-3"
                    >
                      <p>
                        {subIndex + 1}. Product Name: {subItem.productName}
                      </p>
                      <p className="ms-3">
                        Product Quantity: <span>{subItem.productQty}</span>
                        &nbsp;&nbsp; Product Price:{" "}
                        <span>
                          {subItem.productPrice / subItem.productQty}
                        </span>
                      </p>
                      <p className="ms-3">
                        Total Price: <span>{subItem.totalProductPrice}</span>
                        &nbsp;&nbsp; Purchasing Date:{" "}
                        <span>{subItem.purchasedDate}</span>
                      </p>
                      <hr />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default ProductModel;
