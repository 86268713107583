import React, { useEffect, useState } from "react";
import "./CustomerDetails.css";
import { Col, Row } from "react-bootstrap";
import Image from "../../Assets/Admin LMS Image/AdminImage.png";
import userImage from "../../Assets/Admin LMS Image/userImage.png";
import { ActionCustomerDetails } from "../../store/actions/actions-server-data";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import SiderIcons from "../../components/SVG Icons/Svg";

export default function CustomerDetails() {
  const dispatch = useDispatch();
  const { rServerData: ServerData, rLoading: isLoading } = useSelector(
    (state) => state
  );
  const callAPILoad = isLoading?.customerdetailsLoading;
  const userAccessToken = ServerData?.userAccessToken || null;
  const CustomerDetailsData = ServerData?.customerdetails?.data || [];

  const itemsPerPage = 8;

  const [customerDetailsfilteredData, setCustomerDetailsfilteredData] =
    useState([]);
  const [customerDetails, setCustomerDetails] = useState("");
  const [showMoreActivity, setShowMoreActivity] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  // useEffect(() => {
  //   if (userAccessToken) {
  //     dispatch(ActionCustomerDetails());
  //   }
  // }, [dispatch, userAccessToken]);

  useEffect(() => {
    if (!isFiltered) {
      setCustomerDetailsfilteredData([]);
    }
  }, [CustomerDetailsData, isFiltered]);

  const timeDateFormate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (customerDetails.trim() !== "") {
      const filteredData = CustomerDetailsData.filter(
        (item) =>
          item.CustomerEmail.toLowerCase().includes(
            customerDetails.trim().toLowerCase()
          ) ||
          item.userId == customerDetails.trim() ||
          item.Firstname.toLowerCase().includes(
            customerDetails.trim().toLowerCase()
          ) ||
          item.Lastname.toLowerCase().includes(
            customerDetails.trim().toLowerCase()
          ) ||
          `${item.Firstname.toLowerCase()} ${item.Lastname.toLowerCase()}`.includes(
            customerDetails.trim().toLowerCase()
          )
      );

      if (filteredData.length === 0) {
        alert("Enter a valid email ID, name or customer ID");
      } else {
        setCustomerDetailsfilteredData(filteredData);
        setIsFiltered(true);
      }
    } else {
      setCustomerDetailsfilteredData([]);
      setIsFiltered(false);
    }
  };

  const toggleShowMoreActivity = () => {
    setShowMoreActivity((prev) => !prev);
  };

  const handleChange = (e) => {
    setCustomerDetails(e.target.value);
  };

  return (
    <div className="customerDetailsPage">
      {false ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="idSearchCustomerDetails">
            <div className="container-fluid ">
              <form action="">
                <Row>
                  <Col sm={11} lg={11} className="p-0">
                    <div className="me-4 position-relative">
                      <div class="form-group">
                        <input
                          type="text"
                          name="DetailsInput"
                          class="form-control"
                          id="CustomerInput"
                          value={customerDetails}
                          onChange={handleChange}
                          placeholder="Search by email ID, name or customer ID"
                        />
                      </div>
                      {customerDetails && (
                        <span
                          className="clear-icon"
                          id="clearEndDateIcon"
                          style={{
                            cursor: "pointer",
                            display: "block",
                            color: "#402F7B",
                            position: "absolute",
                            right: "20px",
                            top: "5px",
                            width: "6px",
                            height: "0px",
                          }}
                          onClick={() => {
                            setCustomerDetails("");
                          }}
                        >
                          &#10006;
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col sm={1} lg={1} className="p-0">
                    <div className="ms-0">
                      <button
                        type="submit"
                        id="filterSubmitBtn"
                        class="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        search
                      </button>
                      <button
                        type="submit"
                        className="btn"
                        onClick={handleSubmit}
                        id="filterSubmitIcons"
                      >
                        <SiderIcons icons={8} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          {customerDetailsfilteredData.length == 0 ? (
            <div>
              <div
                className="CustomerDetails mt-4"
                id="CustomerDetailsCard"
                disabled
              >
                <div className="d-flex px-5 pt-5">
                  <div>
                    <img src={Image} alt="" />
                  </div>
                  <div className="d-flex ps-3 text-center align-items-center">
                    <h3 className="userNameCustomerDetails">User Name</h3>
                    <p className="ms-3 m-0 fs-5 pb-2 CustIDCustomerDetails">
                      Cust ID: Id
                    </p>
                  </div>
                </div>
                <hr />
                <div className="pb-2 pt-3 px-3 mx-5">
                  <div className="container-fluid">
                    <Row className="test-start">
                      <Col sm={12} lg={5} className=" ">
                        <div className="profile-body d-flex">
                          <div className="profile-column text-lg-end">
                            <p>
                              <strong>Age:</strong>
                            </p>
                            <p>
                              <strong>Email ID:</strong>
                            </p>
                            <p>
                              <strong>Contact:</strong>
                            </p>
                            <p>
                              <strong>Address:</strong>
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} lg={7}>
                        <div className="profile-body d-flex">
                          <div className="profile-column text-lg-end">
                            <p>
                              <strong>Purchase date:</strong> {}
                            </p>
                            <p>
                              <strong>Course Price:</strong> {}
                            </p>
                            <p>
                              <strong>New/Extension:</strong> {}
                            </p>
                            <p>
                              <strong>How did you hear about us:</strong> {}
                            </p>
                            <p>
                              <strong>Biomagnetism Interest:</strong> {}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            customerDetailsfilteredData.map((item) => (
              <div className="CustomerDetails mt-4">
                <div className="px-5 pt-5">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <img
                          src={item.CustomerImage || Image}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "70px",
                            height: "70px",
                          }}
                        />
                      </div>
                      <div className="d-flex ps-3 text-center align-items-center">
                        <h3 className="userNameCustomerDetails">
                          {item.Firstname + " " + item.Lastname}
                        </h3>
                        <p className="ms-3 m-0 fs-5 pb-2 CustIDCustomerDetails">
                          Cust ID: {item.userId || "NA"}
                        </p>
                      </div>
                    </div>
                    <div>
                      <span
                        className="clear-icon "
                        id="clearEndDateIcon"
                        style={{
                          cursor: "pointer",
                          display: "block",
                          color: "#99a3a4",
                          position: "relative",
                          width: "15px",
                          height: "0px",
                          fontSize: "25px",
                        }}
                        onClick={() => {
                          const newCustomerDetails =
                            customerDetailsfilteredData.filter(
                              (customer) => customer.userId !== item.userId
                            );
                          setCustomerDetailsfilteredData(newCustomerDetails);
                        }}
                      >
                        &#10006;
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  <div className="pb-3 pt-3 px-3 mx-3">
                    <div className="container-fluid">
                      <Row>
                        <Col lg={3} className="px-5">
                          <div className="profile-body d-flex">
                            <div className="profile-column text-end">
                              <p>
                                <strong>Age:</strong>
                              </p>
                              <p>
                                <strong>Email ID:</strong>
                              </p>
                              <p>
                                <strong>Contact:</strong>
                              </p>
                              <p>
                                <strong>Address:</strong>
                              </p>
                            </div>
                            <div className="profileDetails px-3">
                              <div key={item.CustomerID}>
                                <p>{item.Age || "NA"}</p>
                                <p>{item.CustomerEmail || "NA"} </p>
                                <p>{item.ContactNumber || "NA"}</p>
                                <p>{item.State || "NA"}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={9}>
                          <div className="profile-body d-flex">
                            <div className="row">
                              <div className="col-6">
                                <div className="profile-column text-end">
                                  <p>
                                    <strong>Purchase date:</strong> {}
                                  </p>
                                  <p>
                                    <strong>Course Price:</strong> {}
                                  </p>
                                  <p>
                                    <strong>New/Extension:</strong> {}
                                  </p>
                                  <p>
                                    <strong>How did you hear about us:</strong>{" "}
                                    {}
                                  </p>
                                  <p>
                                    <strong>Biomagnetism Interest:</strong> {}
                                  </p>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="profile-column px-3">
                                  <div key={item.CustomerID}>
                                    <p>
                                      {timeDateFormate(
                                        item.PurchasedOn || "NA"
                                      )}
                                    </p>
                                    <p>{item.OrderAmount || "NA"} </p>
                                    <p>{"New" || "NA"}</p>
                                    <p style={{ textAlign: "justify" }}>
                                      {item.HowDidYouHearAboutUs || "NA"}
                                    </p>
                                    <p style={{ textAlign: "justify" }}>
                                      {item.BiomagnetismInterest || "NA"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center pe-5">
                    <div className="dropdown me-3"></div>
                    <button
                      className="btn btn-link text-decoration-none view-more-btn"
                      onClick={toggleShowMoreActivity}
                    >
                      {showMoreActivity ? "View Less" : "View More"}
                      {showMoreActivity ? (
                        <i class="fa-solid fa-chevron-up ms-2"></i>
                      ) : (
                        <i class="fa-solid fa-chevron-down ms-2"></i>
                      )}
                    </button>
                  </div>
                  <hr />
                  {showMoreActivity && (
                    <div className="activityStatus mt-4 px-5">
                      <div className="activityStatus d-flex justify-content-between align-items-center">
                        <div className="activityStatustitle">
                          <span>Activity Status</span>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="DateOfactivityStatus mb-2">
                          <span>Today</span>
                        </div>
                        <div className="activityStatusDetails px-3 pt-4">
                          {[1, 2, 3, 4, 5, 6].map((item) => (
                            <React.Fragment key={item}>
                              <div className="d-flex">
                                <div>
                                  <img src={userImage} alt="" id="userImage" />
                                </div>
                                <div className="ms-3">
                                  <p className="m-0 SubscribeDetails">
                                    Magnetic cups purchased at e-store.
                                  </p>
                                  <p className="m-0">June 29, 11:11 AM</p>
                                </div>
                              </div>
                              <hr />
                            </React.Fragment>
                          ))}

                          <div>
                            <div className="DateOfactivityStatus mb-2">
                              <span>28 Jun 2024</span>
                            </div>
                            <div className="activityStatusDetails px-3 pt-4">
                              {[1, 2].map((item) => (
                                <React.Fragment key={item}>
                                  <div className="d-flex">
                                    <div>
                                      <img
                                        src={userImage}
                                        alt=""
                                        id="userImage"
                                      />
                                    </div>
                                    <div className="ms-3">
                                      <p className="m-0 SubscribeDetails">
                                        Magnetic cups purchased at e-store.
                                      </p>
                                      <p className="m-0">June 28, 11:11 AM</p>
                                    </div>
                                  </div>
                                  <hr />
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
